import { RadioGroup } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { RadioButton } from "../components/RadioButton";
import { Numbers, Path } from "../enums";
import { TitleQuestion } from "./TitleQuestion";

interface BedroomCountProps {
  nextPath: Path;
  roomCount: number;
  setValue: React.Dispatch<Numbers>;
  value: Numbers | null;
}

export const BedroomCountStep: React.FC<BedroomCountProps> = ({
  nextPath,
  roomCount,
  setValue,
  value,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <TitleQuestion text="How many bedroom" />
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        onChange={(v) => {
          setValue(v.target.value as Numbers);
          navigate(nextPath);
        }}
        onClick={() => navigate(nextPath)}
        value={value}
      >
        {Object.entries(Numbers)
          .slice(0, roomCount)
          .map(([key, value]) => (
            <RadioButton key={key} value={value} />
          ))}
      </RadioGroup>
    </>
  );
};
