import { FormControlLabel, Radio } from "@mui/material";
import React from "react";

interface RadioButtonProps {
  value: string;
}

export const RadioButton: React.FC<RadioButtonProps> = ({ value }) => {
  return (
    <FormControlLabel
      sx={{ color: "white" }}
      value={value}
      control={<Radio size="medium" sx={{ color: "white" }} />}
      label={value}
    />
  );
};
