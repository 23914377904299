import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

interface PriceRangeStepProps {
  ranges: {
    title: string;
    price: string;
    path: string;
  }[];
}

export const PriceRangeStep: React.FC<PriceRangeStepProps> = ({ ranges }) => {
  return (
    <div style={{ textAlign: "center" }}>
      {ranges.map((range, index) => (
        <div key={index}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {range.title}
          </Typography>
          <Typography variant="h6">from {range.price} excl. VAT</Typography>
          <Box height={12} />
          <Button
            disableElevation
            href="/price-ranges"
            variant="contained"
            color="inherit"
            sx={{ color: "#1C3458", fontWeight: "bold", borderRadius: "50px" }}
          >
            See more
          </Button>
          <Box height={26} />
        </div>
      ))}
    </div>
  );
};
