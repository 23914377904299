import { FormLabel, RadioGroup } from "@mui/material";
import React from "react";
import { Path, PropertyType } from "../enums";
import { useNavigate } from "react-router-dom";
import { TitleQuestion } from "./TitleQuestion";
import { RadioButton } from "../components/RadioButton";

interface PropertyTypeStepProps {
  getNextPath: (propertyType: PropertyType) => Path;
  setValue: React.Dispatch<PropertyType>;
  value: PropertyType | null;
}

export const PropertyTypeStep: React.FC<PropertyTypeStepProps> = ({
  getNextPath,
  setValue,
  value,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <FormLabel id="demo-controlled-radio-buttons-group">
        <TitleQuestion text="Property type" />
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        onChange={(value) => {
          setValue(value.target.value as PropertyType);
          setTimeout(
            () => navigate(getNextPath(value.target.value as PropertyType)),
            300
          );
        }}
        // onClick={() => navigate(getNextPath(value as PropertyType))}
        value={value}
      >
        {Object.entries(PropertyType).map(([key, value]) => (
          <RadioButton key={key} value={value} />
        ))}
      </RadioGroup>
    </>
  );
};
