import React, { useMemo, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Numbers, Path, PropertyType, YesNo } from "./enums";
import { BalconyStep } from "./steps-form/BalconyStep";
import { BedroomCountStep } from "./steps-form/BedroomCountStep";
import { BuiltInCosetStep } from "./steps-form/BuiltInCosetStep";
import { DinningRoomStep } from "./steps-form/DinningRoomStep";
import { PriceRangeStep } from "./steps-form/PriceRangeStep";
import { PropertyTypeStep } from "./steps-form/PropertyTypeStep";
import { computeTotalBudget } from "./utils/computeTotalBudget";
import { formatCurrency } from "./utils/formatCurrency";

export const MainForm: React.FC = () => {
  const [propertyType, setPropertyType] = useState<PropertyType | null>(null);
  const [bedRoomCount, setBedRoomCount] = useState<Numbers | null>(null);
  const [hasDinningRoom, setHasDinningRoom] = useState<YesNo | null>(null);
  const [hasBuiltInCoset, setHasBuiltInCoset] = useState<YesNo | null>(null);
  const [hasBalcony, setHasBalcony] = useState<YesNo | null>(null);
  console.log({
    propertyType,
    bedRoomCount,
    hasBuiltInCoset,
    hasDinningRoom,
    hasBalcony,
  });

  const maxBedroomCount = propertyType === PropertyType.F3 ? 2 : 3;
  const getNextPathPropertyStep = (propertyType: PropertyType) =>
    propertyType === PropertyType.STUDIO || propertyType === PropertyType.F2
      ? Path.DINNING_ROOM_STEP
      : Path.BEDROOM_STEP;

  const totalBudgetAmount = useMemo(
    () =>
      computeTotalBudget({
        propertyType,
        bedRoomCount,
        hasDinningRoom,
        hasBuiltInCoset,
        hasBalcony,
      }),
    [propertyType, bedRoomCount, hasDinningRoom, hasBuiltInCoset, hasBalcony]
  );
  const priceRanges = [
    {
      title: "Basic:",
      price: formatCurrency(totalBudgetAmount.low),
      path: Path.BASIC_RANGE_STEP,
    },
    {
      title: "Mid-Range:",
      price: formatCurrency(totalBudgetAmount.medium),
      path: Path.MEDIUM_RANGE_STEP,
    },
    {
      title: "Premium:",
      price: formatCurrency(totalBudgetAmount.high),
      path: Path.PREMIUM_RANGE_STEP,
    },
  ];

  return (
    <div style={{ margin: "0 auto", maxWidth: "400px", padding: "0 24px" }}>
      <Routes>
        <Route
          index
          element={
            <PropertyTypeStep
              getNextPath={getNextPathPropertyStep}
              setValue={setPropertyType}
              value={propertyType}
            />
          }
        />
        <Route
          path={Path.BEDROOM_STEP}
          element={
            <BedroomCountStep
              nextPath={Path.DINNING_ROOM_STEP}
              roomCount={maxBedroomCount}
              setValue={setBedRoomCount}
              value={bedRoomCount}
            />
          }
        />
        <Route
          path={Path.DINNING_ROOM_STEP}
          element={
            <DinningRoomStep
              nextPath={Path.BUILD_IN_CLOSET_STEP}
              setValue={setHasDinningRoom}
              value={hasDinningRoom}
            />
          }
        />
        <Route
          path={Path.BUILD_IN_CLOSET_STEP}
          element={
            <BuiltInCosetStep
              nextPath={Path.BALCONY_STEP}
              setValue={setHasBuiltInCoset}
              value={hasBuiltInCoset}
            />
          }
        />
        <Route
          path={Path.BALCONY_STEP}
          element={
            <BalconyStep
              nextPath={Path.PRICE_RANGES_STEP}
              setValue={setHasBalcony}
              value={hasBalcony}
            />
          }
        />
        <Route
          path={Path.PRICE_RANGES_STEP}
          element={<PriceRangeStep ranges={priceRanges} />}
        />
      </Routes>
    </div>
  );
};
