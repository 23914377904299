import { RadioGroup } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { RadioButton } from "../components/RadioButton";
import { Path, YesNo } from "../enums";
import { TitleQuestion } from "./TitleQuestion";

interface DinningRoomStepProps {
  nextPath: Path;
  setValue: React.Dispatch<YesNo>;
  value: YesNo | null;
}

export const DinningRoomStep: React.FC<DinningRoomStepProps> = ({
  nextPath,
  setValue,
  value,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <TitleQuestion text="Is there a dinning room area" />
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        onChange={({ target }) => {
          setValue(target.value as YesNo);
          navigate(nextPath);
        }}
        onClick={() => navigate(nextPath)}
        value={value}
      >
        {Object.entries(YesNo).map(([key, value]) => (
          <RadioButton key={key} value={value} />
        ))}
      </RadioGroup>
    </>
  );
};
