import { Numbers, PropertyType, YesNo } from "../enums";

interface ComputeTotalBudgetProps {
  propertyType: PropertyType | null;
  bedRoomCount: Numbers | null;
  hasDinningRoom: YesNo | null;
  hasBuiltInCoset: YesNo | null;
  hasBalcony: YesNo | null;
}

// 0 ch	2 017,20 €	4 048,80 €	8 005,20 €
// 1 ch	552,00 €	768,00 €	3 720,00 €
// SAM	132,00 €	300,00 €	1 332,00 €
// 1 ch additional	344,40 €	744,00 €	1 641,60 €
// No closet :	150,00 €	400,00 €	1 000,00 €
// Balcony	300,00 €	500,00 €	1 200,00 €
const values = {
  BASE: {
    low: 2_017.2,
    medium: 4_048.8,
    high: 8_005.2,
  },
  FIRST_ROOM: {
    low: 552,
    medium: 768,
    high: 3_720,
  },
  ADDITIONAL_ROOM: {
    low: 344.4,
    medium: 744,
    high: 1_641.6,
  },
  DINNING_ROOM: {
    low: 132,
    medium: 300,
    high: 1_332,
  },
  CLOSET: {
    low: 150,
    medium: 400,
    high: 1_000,
  },
  BALCONY: {
    low: 300,
    medium: 500,
    high: 1_200,
  },
};

export const computeTotalBudget = ({
  propertyType,
  bedRoomCount,
  hasDinningRoom,
  hasBuiltInCoset,
  hasBalcony,
}: ComputeTotalBudgetProps): Record<"low" | "medium" | "high", number> => {
  const res = {
    low: values.BASE.low,
    medium: values.BASE.medium,
    high: values.BASE.high,
  };

  (["low", "medium", "high"] as const).forEach((range) => {
    range as keyof typeof res;
    if (propertyType !== PropertyType.STUDIO) {
      res[range] += values.FIRST_ROOM[range];

      if (bedRoomCount === Numbers.TWO) {
        res[range] += values.ADDITIONAL_ROOM[range];
      } else if (bedRoomCount === Numbers.THREE) {
        res[range] += values.ADDITIONAL_ROOM[range] * 2;
      }
    }

    if (hasDinningRoom === YesNo.YES) {
      res[range] += values.DINNING_ROOM[range];
    }

    if (hasBuiltInCoset === YesNo.YES) {
      res[range] += values.CLOSET[range];
    }

    if (hasBalcony === YesNo.YES) {
      res[range] += values.BALCONY[range];
    }
  });

  return res;
};
