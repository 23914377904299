import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

interface TitleQuestionProps {
  text: string;
}

export const TitleQuestion: React.FC<TitleQuestionProps> = ({ text }) => {
  return (
    <>
      <Typography variant="h6" sx={{ color: "white", fontWeight: "bold" }}>
        {text}
      </Typography>
      <Box height={8} />
    </>
  );
};
