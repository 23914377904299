import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import "./App.css";
import logo from "./flair-logo.png";
import { MainForm } from "./MainForm";

function App() {
  const navigate = useNavigate();

  return (
    <div className="App">
      <Box style={{ height: "24px" }} />
      <img
        src={logo}
        className="App-logo"
        alt="logo"
        width="200px"
        style={{ display: "block", margin: "0 auto", cursor: "pointer" }}
        onClick={() => navigate("/")}
      />
      <Box style={{ height: "93px" }} />
      <MainForm />
    </div>
  );
}

export default App;
