export enum PropertyType {
  STUDIO = "Studio",
  F2 = "F2",
  F3 = "F3",
  F4 = "F4",
}

export enum Numbers {
  ONE = "1",
  TWO = "2",
  THREE = "3",
}

export enum YesNo {
  YES = "Yes",
  NO = "No",
}

export enum Path {
  BASIC_RANGE_STEP = "/basic-range-step",
  MEDIUM_RANGE_STEP = "/medium-range-step",
  PREMIUM_RANGE_STEP = "/premium-range-step",
  BEDROOM_STEP = "/bedroom-step",
  DINNING_ROOM_STEP = "/dinning-room-step",
  BUILD_IN_CLOSET_STEP = "/built-in-coset-step",
  BALCONY_STEP = "/balcony-step",
  PRICE_RANGES_STEP = "/price-ranges-step",
}
